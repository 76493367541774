import React from 'react';
import styled from 'styled-components';
import DrawToolItem from './DrawToolItem';
import CursorIcon from 'public/images/cursor.svg';
import RectangleIcon from 'public/images/rectangle.svg';
import PolygonIcon from 'public/images/polygon.svg';
import LineIcon from 'public/images/line.svg';
import PointIcon from 'public/images/point.svg';

type Props = {};

export default function DrawToolBox({}: Props) {
  return (
    <Container>
      <DrawToolItem icon={<CursorIcon />} title={'Normal Mode'} value={'select'} />
      <DrawToolItem icon={<RectangleIcon />} title={'Rectangle Mode'} value={'rectangle'} />
      <DrawToolItem icon={<PolygonIcon />} title={'Polygon Mode'} value={'polygon'} />
      <DrawToolItem icon={<LineIcon />} title={'Line Mode'} value={'line'} />
      <DrawToolItem icon={<PointIcon />} title={'Point Mode'} value={'point'} />
    </Container>
  );
}

const Container = styled.div`
  margin-left: 50px;

  display: flex;
  flex: 1 1 0;
`;
