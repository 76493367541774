import { Map, ScaleControl, NavigationControl, GeolocateControl, MapRef, ViewState, ViewStateChangeEvent, MapboxEvent } from 'react-map-gl';
import { useEffect, useRef, useState } from 'react';

// mapbox 디자인 요소 css import
import 'mapbox-gl/dist/mapbox-gl.css';
import styled from 'styled-components';
import MapLayer from './MapLayer';
import { useSelector } from 'react-redux';
import { RootState } from '@store/index';
import MapDraw from './MapDraw';

type Props = {};

export default function MapView({}: Props) {
  const mapStyle = useSelector((state: RootState) => state.control.mapStyle);
  const { location: searchLocation, point: pointLocation } = useSelector((state: RootState) => state.control);
  const mapRef = useRef<MapRef>(null);
  const [mapStyleUrl, setMapStyleUrl] = useState<string>(process.env.NEXT_PUBLIC_MAPBOX_SATELLITE_STYLE!);
  const [mapLoaded, setMapLoaded] = useState<boolean>(false);
  const [mapViewState, setMapViewState] = useState<ViewState>({
    latitude: 35.070768,
    longitude: 129.053405,
    zoom: 13,
    bearing: 0,
    pitch: 0,
    padding: { bottom: 0, left: 0, right: 0, top: 0 },
  });

  useEffect(() => {
    if (!mapRef.current || searchLocation === null) {
      return;
    }

    if (searchLocation.bbox === undefined) {
      mapRef.current.flyTo({ center: [searchLocation.center[0], searchLocation.center[1]], zoom: 16 });
    } else {
      mapRef.current.fitBounds([
        [searchLocation.bbox[0], searchLocation.bbox[1]],
        [searchLocation.bbox[2], searchLocation.bbox[3]],
      ]);
    }
  }, [searchLocation]);

  useEffect(() => {
    if (!mapRef.current || pointLocation === null) {
      return;
    }

    mapRef.current.flyTo({ center: [pointLocation[0], pointLocation[1]], zoom: 16 });
  }, [pointLocation]);

  const onMapMoveHandler = (e: ViewStateChangeEvent) => {
    setMapViewState({ ...e.viewState });
  };

  const onMapLoadHandler = (e: MapboxEvent) => {
    setMapLoaded(true);
  };

  useEffect(() => {
    if (mapStyle === 'satellite') setMapStyleUrl(process.env.NEXT_PUBLIC_MAPBOX_SATELLITE_STYLE!);
    else if (mapStyle === 'street') setMapStyleUrl(process.env.NEXT_PUBLIC_MAPBOX_STREET_STYLE!);
    else if (mapStyle === 'light') setMapStyleUrl(process.env.NEXT_PUBLIC_MAPBOX_LIGHT_STYLE!);
  }, [mapStyle]);

  return (
    <MapViewContainer>
      <Map
        ref={mapRef}
        id={'mainmap'}
        {...mapViewState}
        mapStyle={mapStyleUrl}
        mapboxAccessToken={process.env.NEXT_PUBLIC_MAPBOX_TOKEN}
        onMove={onMapMoveHandler}
        onLoad={onMapLoadHandler}
        renderWorldCopies={false}
        dragRotate={false}
        maxBounds={[-180, -85, 180, 85]}
        localFontFamily={'NanumGothic, NanumGothicBold, NanumGothicExtraBold'}
      >
        <ScaleControl position="bottom-right" maxWidth={90} />
        <NavigationControl position="bottom-right" showCompass={false} />
        <GeolocateControl position="bottom-right" showUserLocation={false} />
        {mapLoaded && mapRef.current && <MapLayer map={mapRef.current} />}
        {mapRef.current && <MapDraw map={mapRef.current} />}
      </Map>
    </MapViewContainer>
  );
}

const MapViewContainer = styled.div`
  width: 100vw;
  height: 100vh;

  .mapboxgl-popup-tip {
    display: none;
  }

  .mapboxgl-popup-content {
    padding: 0px;
  }
`;
