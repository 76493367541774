import area from '@turf/area';
import bbox from '@turf/bbox';
import { centerOfMass } from '@turf/center-of-mass';
import { lineString, polygon } from '@turf/helpers';
import length from '@turf/length';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Popup } from 'react-map-gl';
import styled from 'styled-components';

type Props = {
  coordinates: number[][];
  type: string;
  deleteFeatures: () => void;
};

const getDrawPopupLocation = (data: number[][]): [number, number] => {
  let lat;
  let maxLon;

  data.map((item) => {
    if (maxLon === null || maxLon === undefined || maxLon < item[0]) {
      lat = item[1];
      maxLon = item[0];
    }
  });

  return [maxLon, lat];
};

export default function DrawPopup({ coordinates, type, deleteFeatures }: Props) {
  const [location, setLocation] = useState<[number, number]>([0, 0]);
  const [bboxValue, setBboxValue] = useState<number[]>([0, 0, 0, 0]);
  const [unit, setUnit] = useState<number>(-1);
  const [value, setValue] = useState<number>(0);

  useEffect(() => {
    if (type === 'Point') {
      setLocation([coordinates[0][0], coordinates[0][1]]);
    } else {
      setLocation(getDrawPopupLocation(coordinates));
    }
  }, [coordinates]);

  useEffect(() => {
    if (type === 'LineString') {
      setValue(length(lineString(coordinates), { units: 'kilometers' }));
      setUnit(0);
      setBboxValue(bbox(lineString(coordinates)));
    } else if (type === 'Polygon') {
      const areaValue = area(polygon([coordinates]));

      setValue(areaValue > 1000000 ? areaValue / 1000000 : areaValue);
      setUnit(areaValue > 1000000 ? 1 : 2);
      setBboxValue(bbox(polygon([coordinates])));
    }
  }, [coordinates, type]);

  useEffect(() => {
    const keyDownHandler = (e: KeyboardEvent) => {
      if (e.key === 'Delete') {
        e.preventDefault();
        deleteFeatures();
      }
    };

    if (coordinates) {
      document.addEventListener('keydown', keyDownHandler);

      return () => {
        document.removeEventListener('keydown', keyDownHandler);
      };
    }
  }, [coordinates]);

  const onClickEventHandler = (e: React.MouseEvent, type: string) => {
    e.preventDefault();

    if (type === 'measurement') {
      navigator.clipboard.writeText(`${value.toFixed(3)} ${getUnit(true)}`);
      toast.success('넓이가 복사되었습니다!');
    } else if (type === 'features') {
      navigator.clipboard.writeText(`${JSON.stringify(coordinates)}`);
      toast.success('AOI가 복사되었습니다!');
    } else if (type === 'bbox') {
      navigator.clipboard.writeText(`${JSON.stringify(bboxValue)}`);
      toast.success('BBox가 복사되었습니다!');
    } else if (type === 'center') {
      navigator.clipboard.writeText(centerOfMass(polygon([coordinates])).geometry.coordinates.join(', '));
      toast.success('영역의 중심 좌표가 복사되었습니다!');
    } else if (type === 'point') {
      navigator.clipboard.writeText(coordinates[0].join(', '));
      toast.success('좌표가 복사되었습니다!');
    }
  };

  const getUnit = (type?: boolean) => {
    if (type) {
      if (unit === 0) {
        return 'km';
      } else if (unit === 1) {
        return 'km2';
      } else if (unit === 2) {
        return 'm2';
      } else {
        return '';
      }
    } else {
      if (unit === 0) {
        return <div>km</div>;
      } else if (unit === 1) {
        return (
          <div>
            km<sup>2</sup>
          </div>
        );
      } else if (unit === 2) {
        return (
          <div>
            m<sup>2</sup>
          </div>
        );
      } else {
        return <></>;
      }
    }
  };

  return (
    <Popup
      latitude={location[1]}
      longitude={location[0]}
      closeOnClick={false}
      closeButton={false}
      anchor={'top-left'}
      offset={[20, 0] as [number, number]}
    >
      <Container>
        {type !== 'Point' ? (
          <>
            <div
              className="measurement"
              title={type === 'LineString' ? '길이' : '넓이'}
              onClick={(e: React.MouseEvent) => onClickEventHandler(e, 'measurement')}
            >
              {type === 'LineString' ? '길이' : '넓이'} : {value.toFixed(3)}&nbsp;{getUnit()}
            </div>
            <div className="features" title="AOI 좌표" onClick={(e: React.MouseEvent) => onClickEventHandler(e, 'features')}>
              AOI 좌표값 복사
            </div>
            <div className="bbox" title="BBox 좌표" onClick={(e: React.MouseEvent) => onClickEventHandler(e, 'bbox')}>
              BBox 좌표값 복사
            </div>
            {type !== 'LineString' && (
              <div className="center" title="중심 좌표" onClick={(e: React.MouseEvent) => onClickEventHandler(e, 'center')}>
                중심 좌표값 복사
              </div>
            )}
          </>
        ) : (
          <div className="point" title="좌표" onClick={(e: React.MouseEvent) => onClickEventHandler(e, 'point')}>
            좌표값 복사
          </div>
        )}
      </Container>
    </Popup>
  );
}

const Container = styled.div`
  background-color: #ffffff;
  border-radius: 3px;

  font-variation-settings: 'wght' 600;
  line-height: 30px;
  font-size: 15px;

  width: 200px;

  & > div {
    padding: 5px 10px;
    display: flex;
    align-items: center;

    cursor: pointer;

    &:hover {
      background-color: #e6cfeb;
    }
  }
`;
