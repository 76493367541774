import React, { useEffect, useRef } from 'react';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { controlAction } from '@store/modules/controlReducer';

type Props = {};

export default function LocationSearcher({}: Props) {
  const dispatch = useDispatch();
  const geocodeRef = useRef<HTMLDivElement>(null);
  const geocoder = new MapboxGeocoder({
    accessToken: process.env.NEXT_PUBLIC_MAPBOX_TOKEN!,
    language: 'en-US',
    reverseGeocode: true,
    // language: activeLocale === 'en' ? 'en-US' : 'ko-KR',
    // types: 'country, region, place, postcode, locality, neighborhood',
  });

  geocoder.on('result', (e) => {
    dispatch(
      controlAction.updateLocationInfo({
        bbox: e.result.bbox,
        center: e.result.center,
        place_name: e.result.place_name,
      }),
    );
  });

  useEffect(() => {
    geocoder.addTo(geocodeRef.current!);
  }, []);

  return <CustomLocationSearcher ref={geocodeRef} />;
}

export const CustomLocationSearcher = styled.div`
  .mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
    font-size: 14px;
    width: 100%;
  }
  .mapboxgl-ctrl-geocoder--pin-right > button.mapboxgl-ctrl-geocoder--button {
    display: none !important;
  }
  .mapboxgl-ctrl-geocoder--icon-search {
    left: initial;
    right: 5px;
    top: 5px;
  }
  .mapboxgl-ctrl-geocoder--input {
    font-family: 'SUIT';
    border-style: none;
    padding: 6px 2px;
    padding-left: 10px;
    padding-right: 30px;
    font-size: 14px;
    letter-spacing: 0;
    outline: none;
    color: #000;
    width: 100%;
    height: 30px;
    border-radius: 0px;
    background-color: transparent;
  }
  .mapboxgl-ctrl-geocoder--input::placeholder {
    font-size: 14px;
    opacity: 0.5;
    font-variation-settings: 'wght' 300;
  }
  .mapboxgl-ctrl-geocoder,
  .mapboxgl-ctrl-geocoder .suggestions {
    box-shadow: none;
  }
  .suggestions {
    background-color: #fff;
    border: 1px solid #ebebeb;
  }
`;
