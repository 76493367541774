import React, { useCallback, useEffect, useState } from 'react';
import { MapRef } from 'react-map-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import DrawRectangle from 'mapbox-gl-draw-rectangle-mode';
// import { CircleMode, DragCircleMode } from 'mapbox-gl-draw-circle';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { controlAction } from '@store/modules/controlReducer';

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import DrawPopup from './DrawPopup';

type Props = {
  map: MapRef;
};

const createFeature = (area: number[][], layerName: string) => {
  return {
    id: layerName,
    properties: {},
    type: 'Feature',
    geometry: {
      coordinates: [area],
      type: 'Polygon',
    },
  };
};

export default function MapDraw({ map }: Props) {
  const [features, setFeatures] = useState({});
  const [type, setType] = useState<string>('');
  const [coordinates, setCoordinates] = useState<number[][]>([]);
  const [selectedFeature, setSelectedFeature] = useState<string>('');

  const selectMode = useSelector((state: RootState) => state.control.mapDrawMode);
  const dispatch = useDispatch();

  const customMode: { [modeKey: string]: MapboxDraw.DrawMode | MapboxDraw.DrawCustomMode } = {
    draw_rectangle: DrawRectangle,
    // draw_circle: CircleMode,
  };

  const draw: MapboxDraw = new MapboxDraw({
    displayControlsDefault: false,
    controls: false,
    boxSelect: true,
    modes: Object.assign(customMode, MapboxDraw.modes),
    defaultMode: 'simple_select',
    userProperties: true,
    styles: DrawCustomStyle,
  });

  const changeSelectStatus = useCallback((id: string) => {
    draw.changeMode('simple_select', { featureIds: [id] });
    setSelectedFeature(id);
  }, []);

  const changeDrawMode = useCallback((mode: string) => {
    // @ts-ignore:next-line
    draw.changeMode(mode);
  }, []);

  const addFeature = useCallback((area: number[][]) => {
    deleteAllFeatures();
    const layerName = 'myArea_selected';
    const newFeatures = createFeature(area, layerName);

    draw.add(newFeatures as any);
    setFeatures({ myArea_selected: newFeatures });
    draw.changeMode('simple_select', { featureIds: layerName });
    setSelectedFeature(layerName);
  }, []);

  const deleteAllFeatures = useCallback(() => {
    setFeatures(draw.deleteAll().getAll().features);
    dispatch(controlAction.changeMapDrawMode('select'));
    setSelectedFeature('');
  }, []);

  const onDrawCreateAndUpdate = useCallback((e) => {
    setFeatures((currFeatures) => {
      if (e.type === 'draw.create') {
        Object.keys(currFeatures).map((item) => draw.delete(item));
      }

      const newFeatures = {};
      for (const f of e.features) {
        newFeatures[f.id] = f;
      }
      return newFeatures;
    });
  }, []);

  const onDrawDelete = useCallback((e) => {
    setFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        delete newFeatures[f.id];
      }
      return newFeatures;
    });
  }, []);

  const onDrawModeChange = useCallback((e) => {
    if (e.mode === 'simple_select') {
      dispatch(controlAction.changeMapDrawMode('select'));
    }
  }, []);

  const onDrawSelect = useCallback((e) => {
    console.log(e);
    if (e.features.length === 1) {
      setSelectedFeature(e.features[0].id);
    } else {
      // setSelectedFeature('');
    }
  }, []);

  useEffect(() => {
    map.addControl(draw);
    map.on('draw.create', onDrawCreateAndUpdate);
    map.on('draw.update', onDrawCreateAndUpdate);
    map.on('draw.delete', onDrawDelete);
    map.on('draw.modechange', onDrawModeChange);
    map.on('draw.selectionchange', onDrawSelect);
  }, []);

  useEffect(() => {
    if (selectMode === 'point') {
      changeDrawMode('draw_point');
    } else if (selectMode === 'line') {
      changeDrawMode('draw_line_string');
    } else if (selectMode === 'polygon') {
      changeDrawMode('draw_polygon');
    } else if (selectMode === 'rectangle') {
      changeDrawMode('draw_rectangle');
    } else if (selectMode === 'delete') {
      changeDrawMode('draw_static');
    } else if (selectMode === 'select') {
      if (Object.keys(features).length > 0) {
        changeSelectStatus(Object.keys(features)[0]);
      }
    }
  }, [selectMode]);

  useEffect(() => {
    if (Object.keys(features).length === 1) {
      const id = Object.keys(features)[0];
      setType(features[id].geometry.type);
      if (features[id].geometry.type === 'LineString') {
        setCoordinates(features[id].geometry.coordinates);
      } else if (features[id].geometry.type === 'Point') {
        setCoordinates([features[id].geometry.coordinates]);
      } else {
        setCoordinates(features[id].geometry.coordinates[0]);
      }
    } else {
      setSelectedFeature('');
      setCoordinates([]);
      setType('');
    }
  }, [features]);

  return (
    <>
      {coordinates.length > 0 && (
        <>
          <DrawPopup coordinates={coordinates} type={type} deleteFeatures={deleteAllFeatures} />
        </>
      )}
    </>
  );
}

const DrawCustomStyle = [
  {
    id: 'highlight-active-points-border',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'feature'], ['==', 'active', 'true']],
    paint: {
      'circle-radius': 15,
      'circle-color': '#7c2d87', // 테두리 역할
    },
  },
  {
    id: 'highlight-active-points-center',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'feature'], ['==', 'active', 'true']],
    paint: {
      'circle-radius': 10,
      'circle-color': '#fff', // 가운데 흰색
    },
  },
  {
    id: 'points-inactive',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'feature'], ['==', 'active', 'false']],
    paint: {
      'circle-radius': 10,
      'circle-color': '#fff', // inactive 상태
    },
  },
  {
    id: 'gl-draw-polygon-fill-inactive',
    type: 'fill',
    filter: ['all', ['==', 'active', 'false'], ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
    paint: {
      'fill-color': '#e6cfeb',
      'fill-outline-color': '#e6cfeb',
      'fill-opacity': 0.6,
    },
  },
  {
    id: 'gl-draw-polygon-fill-active',
    type: 'fill',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': '#7c2d87',
      'fill-outline-color': '#7c2d87',
      'fill-opacity': 0.5,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-inactive',
    type: 'line',
    filter: ['all', ['==', 'active', 'false'], ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#7c2d87',
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-active',
    type: 'line',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#7c2d87',
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-line',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#7c2d87',
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-line-active',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString'], ['==', 'active', 'true']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#7c2d87',
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
    type: 'circle',
    filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
    paint: {
      'circle-radius': 7.5,
      'circle-color': '#7c2d87',
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-inactive',
    type: 'circle',
    filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
    paint: {
      'circle-radius': 5,
      'circle-color': '#fff',
    },
  },
];
